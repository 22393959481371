import {
  Button,
  Input,
  MantineColorsTuple,
  createTheme,
  rem,
} from "@mantine/core";

const ihcBlue: MantineColorsTuple = [
  "#F5F7FD",
  "#EBEFFC",
  "#D7DEFA",
  "#B0BDF5",
  "#8394E3",
  "#5E6FC8",
  "#3041A4",
  "#23308D",
  "#182376",
  "#0F175F",
];

const ihcGreen: MantineColorsTuple = [
  "#FBFEF6",
  "#F7FDED",
  "#EFFCDC",
  "#DCFABA",
  "#BFF195",
  "#A2E376",
  "#79D24C",
  "#59B437",
  "#3D9726",
  "#267918",
];
const ihcGrey: MantineColorsTuple = [
  "#f8f9fa",
  "#f1f3f5",
  "#e9ecef",
  "#dee2e6",
  "#ced4da",
  "#adb5bd",
  "#868e96",
  "#495057",
  "#343a40",
  "#212529",
];

export const theme = createTheme({
  primaryColor: "ihcBlue",
  colors: {
    ihcBlue,
    ihcGreen,
    ihcGrey,
  },
  fontFamily: "Avenir Next LT Pro",
  spacing: {
    xxs: "0.5rem",
    xs: "0.625rem",
    sm: "0.75rem",
    md: "1rem",
    lg: "1.25rem",
    mxl: "1.5rem",
    xl: "2rem",
  },
  fontSizes: {
    xxs: "0.625",
    xs: "0.75rem",
    sm: "0.875rem",
    md: "1rem",
    lg: "1.125rem",
    xl: "1.25rem",
  },
  components: {
    Button: Button.extend({
      vars: (theme, props) => {
        if (props.size === "sm") {
          return {
            root: {
              "--button-height": rem(32),
              "--button-padding-x": rem(16),
            },
          };
        }

        if (props.size === "md") {
          return {
            root: {
              "--button-height": rem(40),
              "--button-padding-x": rem(20),
            },
          };
        }

        if (props.size === "lg") {
          return {
            root: {
              "--button-height": rem(48),
              "--button-padding-x": rem(24),
            },
          };
        }

        return {
          root: {
            "--button-height": rem(32),
            "--button-padding-x": rem(16),
          },
        };
      },
    }),
    Input: Input.extend({
      vars: (theme, props) => {
        if (props.size === "sm") {
          return {
            wrapper: {
              "--input-height": rem(32),
            },
          };
        }

        return {
          wrapper: {
            "--input-height": rem(32),
          },
        };
      },
    }),
  },
});
