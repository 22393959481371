import { Modal, Flex, Button, Text } from "@mantine/core";
import { useAtom } from "jotai";
import { useMemo } from "react";

import { modalAtom } from "~/stores";

function ModalConfirmation() {
  const [modal, setModal] = useAtom(modalAtom);

  function handleClose() {
    setModal({ ...modal, isOpen: false });
  }

  function handleConfirm() {
    modal.onConfirm();
    handleClose();
  }

  function handleCancel() {
    modal.withCancel && modal?.onCancel();
    setModal({ ...modal, isOpen: false });
  }

  const variantColor = useMemo(() => {
    switch (modal.variant) {
      case "error":
        return "red";
      case "success":
        return "ihcGreen";
      case "warning":
        return "yellow";
      default:
        break;
    }
  }, [modal.variant]);
  return (
    <Modal
      opened={modal.isOpen}
      onClose={handleClose}
      withCloseButton={false}
      size="xs"
      radius="md"
      centered
      closeOnClickOutside={false}
    >
      <Flex direction="column" justify="center" align="center">
        {modal.illustration ? modal.illustration : null}
        <Text fw={600} mt="lg" size="md" className="text-center">
          {modal.title}
        </Text>
        <Text fw={300} mt="xs" size="sm" maw={300} className="text-center">
          {modal.description}
        </Text>

        <Flex
          direction="row"
          w="100%"
          justify="space-between"
          gap="md"
          align="center"
          mt="lg"
        >
          {modal.withCancel ? (
            <Button
              size="md"
              variant="default"
              aria-label="Cancel"
              fullWidth
              onClick={handleCancel}
            >
              <Text fw={300}>{modal.cancelText}</Text>
            </Button>
          ) : null}

          <Button
            size="md"
            variant="filled"
            color={variantColor}
            aria-label="Delete"
            fullWidth
            onClick={handleConfirm}
            loading={modal.loading}
          >
            <Text fw={300}>{modal.confirmText}</Text>
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}

export default ModalConfirmation;
